export const nps = {
    name: "nps",
    title: "NPS Question",
    questionJSON: {
      type: "rating",
      rateCount: 11,
      rateMin: 0,
      rateMax: 10,
      minRateDescription: "Highly Unlikely",
      maxRateDescription: "Highly Likely",
      cssClass: "custom-nps-question",
      placeholder: "Select a country...",
      choicesByUrl: {
        url: "https://surveyjs.io/api/CountriesExample",
      },
    },
  };

  
  export const csat = {
    name: "csat",
    title: "CSAT Question",
    questionJSON: {
        type: "rating",
        rateMax: 5,
        rateValues: [
            { value: 1, text: "😞" },
            { value: 2, text: "😕" },
            { value: 3, text: "😐" },
            { value: 4, text: "🙂" },
            { value: 5, text: "😃" },
            { value: 11, text: "N/A" }
        ]
    },
};
  
